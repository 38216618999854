<template>
  <div>
   
      <div class=" field col-12 " style="background-color: #fff;border-radius: 5px;padding:20px;" >
        <label>Selecione a Disciplina</label>
        <select class="p-inputtext p-component" v-model="selec_disciplina_id" @change="Config(selec_disciplina_id)"
          style="appearance: revert !important;width: 100% !important;" >
            <option value="" disabled selected> -- Escolha a Disciplina -- </option>
            <option v-for="n in disciplina" :key="n" :value="n" >{{n.disciplina}}</option>
        </select>    
      </div>

  </div>
</template>

<script>
export default {
  name: "selecionarDisciplinas",
  props: {
    disciplina: { type: Array, default: () => [] },
    infoVerificarPresenca: { type: Array, default: () => [] },
  },
  data() {
    return {
      selec_disciplina_id:null,
    };
  },
  methods: {
    Config(n) {
        this.$emit('Config', n, false);
    }
  },
};
</script>

<style lang="scss" scoped>

</style>

<style scoped>

.escolha-nome-disciplina{
    font-size: 22px;margin-top: 10px;padding-bottom: 15px;
  }

  @media(max-width:500px){
     .escolha-nome-disciplina{
       font-size:16px;
     }
  }

</style>