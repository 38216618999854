<template>
  <div class="table-responsive">
    <br>
    <table class="table table-striped" id="vizualizarNotas">
      <thead>
        <tr>
          <th class="th_per1" style="width: 10%">Nome</th>
          <th
            class="th_per1"
            style="width: 10%"
            v-for="n in tabelaDiasApenasAnosLetivoColunas"
            :key="n.dia"
          >
            <div>
              {{ n.dia }}<br />
              {{ n.semana }}
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(item1, index1) of tabelaDiasApenasAnosLetivoInfo"
          :key="index1"
        >
          <td>
            <p style="padding-left: 10px">{{ item.aluno.nome }} {{item.aluno.sobrenome}}</p>
          </td>
          <td
            style="text-align: center"
            v-for="(item, index) of tabelaDiasApenasAnosLetivoInfo[index1]
              .presencasDias"
            :key="index"
          >
            <div
              v-if="
                item.presenca_1 != undefined && item.presenca_2 != undefined
              "
            >
              <h5
                v-if="item.presenca_1 == 1"
                style="color: #2abf2b; font-weight: 400"
              >
                P
              </h5>
              <h5 v-else style="color: #e40a0a; font-weight: 400">A</h5>
              <h5
                v-if="item.presenca_2 == 1"
                style="color: #2abf2b; font-weight: 400"
              >
                P
              </h5>
              <h5 v-else style="color: #e40a0a; font-weight: 400">A</h5>
            </div>
            <div
              v-if="
                item.presenca_1 != undefined && item.presenca_2 == undefined
              "
            >
              <h5
                style="color: #2abf2b; font-weight: 400"
                v-if="item.presenca_1 == 1"
              >
                P
              </h5>
              <h5 style="color: #e40a0a; font-weight: 400" v-else>A</h5>
            </div>
            <div
              v-if="
                item.presenca_1 == undefined && item.presenca_1 == undefined
              "
            >
              -
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "TableProfPresenca02",
  props: {
    tabelaDiasApenasAnosLetivoColunas: { type: Array, default: () => [] },
    tabelaDiasApenasAnosLetivoInfo: { type: Array, default: () => [] },
    mes:null,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>


<style lang="scss" scoped>
  .table-example--pagination {
    padding-top: 20px;
    text-align: center;
    text-align: -webkit-center;
  }
  .cabecario-turma{
    background-color:#2fc982;
    color: #fff;
    margin-right: 10px;
    padding: 8px;
    border-radius: 5px;
    text-transform: uppercase;
  }
  @media(max-width:500px){
    .titulo-links-escolha {
      font-size: 24px;
    }
  }
</style>

<style scoped>
  .th_per1{
    background-color: #f8f9fa !important;
    color: #495057 !important;
    border-right: 1px solid #e9ecef !important;
    border-bottom: 2px solid #e9ecef !important;
    text-align: center;
    padding: 1rem 1rem;
    font-weight: 600;
  }  
  .th_pn_aluno{
    width:15%;
  }
  @media(max-width:720px){
    .th_pn_aluno{
      width:90%;
    }
  }

  @media(max-width:500px){
    .cabecario-turma{
      display: inline-grid;
      margin-bottom: 5px;
      font-size: 12px;
    }
  }

  .col-icon-univer{
    padding:0px;padding-top: 0px;padding-left: 10px;width: 4%;
  }
  @media(max-width:500px){
    .col-icon-univer{
      width: 15%;
    }
    .col-cturm{
      width: 80%;
    }
  }

  .escolha-nome-disciplina{
    font-size: 22px;margin-top: 10px;padding-bottom: 15px;
  }

  @media(max-width:500px){
     .escolha-nome-disciplina{
       font-size:16px;
     }
  }

  .semana{
    background-color: rgb(239 239 239);
    text-align: center;
  }
  .font-semana{
    font-size: 10px;
  }
  .dias{
    text-align: center;
  }
  .dia-marcado{
    background-color: #4182c5 !important;
    color: #fff;
  }

</style>
