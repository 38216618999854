<template>
  <div>
    <!--<h4>Selecione um dia do mês abaixo para realizar a presença</h4>-->
    <div class="col-md-4" style="padding-bottom: 20px">
        <div class="row" style="padding: 10px">
            <div
                class="col-md-12"
                style="
                padding: 15px;
                border: 1px solid;
                font-size: 23px;
                text-align: center;
                font-weight: 700;
                "
            >
                {{ mes_nome }}
            </div>
        <div
            class="col-md-1"
            v-for="n in tabelaDias"
            :key="n.dia"
            style="text-align: center; border: 1px solid #ced4da; padding: 0;border-radius: 2px;"
        >
            <div v-if="n.letivo == '0'">
            <button type="button" class="btn" style="padding: 0; width: 100%">
                <div class="font-semana" style="background-color: #fff">
                {{ n.semana }}
                </div>
                <div style="background-color: #fff">
                {{ n.dia }}
                </div>
            </button>
            </div>
            <div v-else>
            <button
                type="button"
                class="btn"
                style="padding: 0; width: 100%"
                @click="exibirTabela(n.dia, n.semana, mesNumero, mes)"
            >
                <div v-if="diaSelecionado == n.dia">
                <div
                    class="font-semana"
                    style="background-color: #2fc982; color: #fff"
                >
                    {{ n.semana }}
                </div>
                <div style="background-color: #2fc982; color: #fff">
                    {{ n.dia }}
                </div>
                </div>
                <div v-else>
                <div
                    class="font-semana"
                    style="background-color: #2279e5; color: #fff"
                >
                    {{ n.semana }}
                </div>
                <div style="background-color: #2279e5; color: #fff">
                    {{ n.dia }}
                </div>
                </div>
            </button>
            </div>
        </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "calendario",
  props: {
    tabelaDias: { type: Array, default: () => [] },
    mesNumero: null,
    mes_nome: null,
    mes: null,
    diaSelecionado: null,
  },
  data() {
    return {};
  },
  methods: {
    exibirTabela(dia, semana, mesNumero, mes){
      this.$emit('exibirTabela', dia, semana, mesNumero, mes);
    },
  },
};
</script>

<style lang="scss" scoped>
  .table-example--pagination {
    padding-top: 20px;
    text-align: center;
    text-align: -webkit-center;
  }
  .cabecario-turma{
    background-color:#2fc982;
    color: #fff;
    margin-right: 10px;
    padding: 8px;
    border-radius: 5px;
    text-transform: uppercase;
  }
  @media(max-width:500px){
    .titulo-links-escolha {
      font-size: 24px;
    }
  }
</style>

<style scoped>
  .th_per1{
    background-color: #f8f9fa !important;
    color: #495057 !important;
    border-right: 1px solid #e9ecef !important;
    border-bottom: 2px solid #e9ecef !important;
    text-align: center;
    padding: 1rem 1rem;
    font-weight: 600;
  }
  .th_pn_aluno{
    width:15%;
  }
  @media(max-width:720px){
    .th_pn_aluno{
      width:90%;
    }
  }

  @media(max-width:500px){
    .cabecario-turma{
      display: inline-grid;
      margin-bottom: 5px;
      font-size: 12px;
    }
  }

  .col-icon-univer{
    padding:0px;padding-top: 0px;padding-left: 10px;width: 4%;
  }
  @media(max-width:500px){
    .col-icon-univer{
      width: 15%;
    }
    .col-cturm{
      width: 80%;
    }
  }

  .escolha-nome-disciplina{
    font-size: 22px;margin-top: 10px;padding-bottom: 15px;
  }

  @media(max-width:500px){
     .escolha-nome-disciplina{
       font-size:16px;
     }
  }

  .semana{
    background-color: rgb(239 239 239);
    text-align: center;
  }
  .font-semana{
    font-size: 10px;
  }
  .dias{
    text-align: center;
  }
  .dia-marcado{
    background-color: #4182c5 !important;
    color: #fff;
  }

</style>
